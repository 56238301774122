// extracted by mini-css-extract-plugin
export var caseStudyVideoReview = "d2_lz";
export var caseStudyVideoReview__bgRings = "d2_HP";
export var caseStudyVideoReview__clientImage = "d2_HR";
export var caseStudyVideoReview__container = "d2_HN";
export var caseStudyVideoReview__logo = "d2_H1";
export var caseStudyVideoReview__name = "d2_H2";
export var caseStudyVideoReview__playWrapper = "d2_HF";
export var caseStudyVideoReview__playWrapper_videoReviewImpact = "d2_HH";
export var caseStudyVideoReview__playWrapper_videoReviewIx = "d2_HM";
export var caseStudyVideoReview__playWrapper_videoReviewPmk = "d2_HK";
export var caseStudyVideoReview__playWrapper_videoReviewRef = "d2_HL";
export var caseStudyVideoReview__playWrapper_videoReviewTradeaboat = "d2_HG";
export var caseStudyVideoReview__playWrapper_videoReviewTradervs = "d2_HJ";
export var caseStudyVideoReview__text = "d2_H3";
export var caseStudyVideoReview__title = "d2_HQ";
export var caseStudyVideoReview__videoYoutube = "d2_H0";
export var caseStudyVideoReview__wrapper = "d2_HS";
export var caseStudyVideoReview__wrapper_videoReviewImpact = "d2_HW";
export var caseStudyVideoReview__wrapper_videoReviewIx = "d2_HZ";
export var caseStudyVideoReview__wrapper_videoReviewPmk = "d2_HX";
export var caseStudyVideoReview__wrapper_videoReviewRef = "d2_HY";
export var caseStudyVideoReview__wrapper_videoReviewTradeaboat = "d2_HT";
export var caseStudyVideoReview__wrapper_videoReviewTradervs = "d2_HV";