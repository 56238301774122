// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bZ_kz";
export var caseStudyBackground__lineColor = "bZ_kq";
export var caseStudyHead = "bZ_kC";
export var caseStudyHead__imageWrapper = "bZ_kn";
export var caseStudyProjectsSection = "bZ_kB";
export var caseStudyQuote__bgLight = "bZ_kx";
export var caseStudyQuote__bgRing = "bZ_kw";
export var caseStudyVideoReview = "bZ_lz";
export var caseStudyVideoReview__bgRing = "bZ_lB";
export var caseStudyVideo__ring = "bZ_kF";
export var caseStudy__bgDark = "bZ_km";
export var caseStudy__bgLight = "bZ_kl";
export var caseStudy__bgLightReverse = "bZ_ly";